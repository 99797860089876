import { useMemo } from "react";

import { AccessType } from "@lib/data/schemas/account";

import useOrganizationAccount from "./use-organization-account";

type UseAccessTypeSignature = () => {
  accessType?: AccessType;
  loading: boolean;
  hasFullAccess: boolean;
  hasElevatedAccess: boolean;
  hasLimitedAccess: boolean;
};

const useAccessType: UseAccessTypeSignature = () => {
  const { account, loading: loadingAccount } = useOrganizationAccount();

  const accessType = useMemo(() => {
    return account?.accessType;
  }, [account]);

  return {
    accessType,
    loading: loadingAccount,
    hasFullAccess: accessType === "full",
    hasElevatedAccess: accessType === "elevated" || accessType === "full",
    hasLimitedAccess: accessType !== "elevated" && accessType !== "full",
  };
};

export default useAccessType;
